/* Menu */
.menu__dropdown {
  z-index: 999;
}
#MainContent {
  z-index: 1;
}
/* Header */
#header {
  position: initial !important;
}
header .header__tape {
  background-color: #0410ad;
}
.header__line-top {
  border-bottom: 0px;
}
.header__tape-close {
  display: none;
}
/* Home page container */
.home-page-container {
  padding-left: 40px;
  padding-right: 40px;
  width: calc(100% - 80px);
  display: block;
  margin: 0 auto;
}
/* Product page */
.product-page-info__payments svg {
  width: 98px !important;
  max-width: 98px !important;
}
.template-product .afterpay-logo {
  border: none !important;
  background-color: transparent !important;
  padding: 0px !important;
  cursor: pointer;
}
.product-page .product-page-info__payments .payments {
  display: none;
}
.product-page .container {
  padding-left: 40px;
  padding-right: 40px;
  width: calc(100% - 80px);
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.product-gallery--container {
  max-width: 100%;
}
.product-gallery__thumbnail_item {
  height: 100px;
  width: 100px;
  display: block;
  max-width: 100px;
  margin-left: 10px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-top: 0px !important;
}
.product-gallery__thumbnail_item:nth-child(1) {
  margin-left: 0px !important;
}
.product-gallery__thumbnail_item_inner .rimage,
.product-gallery__thumbnail_item_inner {
  height: 100px;
  width: 100px;
  padding-top: 0px !important;
}
.product-gallery__thumbnail {
  display: flex !important;
  justify-content: center;
}
.product-gallery__thumbnail--collage .row {
  margin-left: 0px;
  margin-right: 0px;
}
.product-page-info .input-quantity {
  width: 110px;
}
.product-page-info .d-flex:nth-child(1) {
  display: block !important;
}
.product-page-info__button-add-to-cart {
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
}
.product-page-info .right-0 {
  height: 33px;
  width: 33px;
  background-color: #0410ad !important;
  margin-right: -2px !important;
}
.product-page-info .left-0 {
  height: 33px;
  width: 33px;
  background-color: #0410ad !important;
  margin-left: -2px !important;
}
.product-page-info .right-0 .icon,
.product-page-info .left-0 .icon {
  fill: #fff;
}
.product-page-info .left-0 .icon {
  position: relative;
  top: 8px;
  left: 6px;
}
.product-page-info .input-quantity--type-04 input {
  width: 111px;
  min-width: 111px;
}
.product-page-info__dynamic-checkout {
  display: none;
}
.product-recommendations .rimage__img--contain,
.product-recommendations .rimage {
  height: auto !important;
  aspect-ratio: 1 / 1;
  width: 100%;
}
.product-recommendations .rimage img {
  object-fit: cover;
}
.product-recommendations {
  max-width: 800px;
  width: 100%;
  display: block;
  margin: 0 auto;
}
.product-recommendations .carousel__item:nth-child(1) {
  padding-right: 5px;
}
.product-recommendations .carousel__item:nth-child(2) {
  padding-left: 5px;
}
.product-recommendations .product-collection__content {
  align-items: center !important;
}
.product-recommendations .product-collection__buttons {
  display: block !important;
  margin: 0 auto;
}
.template-product .product-page-info__payments {
  flex-direction: row !important;
}
#after-pay-original-logo {
  margin-top: -7px;
  float: right;
  margin-left: 5px;
}
.template-product .product-page-info__payments label {
  text-transform: initial !important;
}
.template-product .product-gallery__main_arrow:before {
  background-color: transparent !important;
}
.product-gallery__main_arrows--type-2 .product-gallery__main_arrow--type-next {
  right: -30px !important;
}
.product-gallery__main_arrows--type-2 .product-gallery__main_arrow--type-prev {
  left: -30px !important;
}
/* Tips and Tricks page */
.template-blog h1 {
  margin-bottom: 0px;
}
.template-blog h2 {
  margin-bottom: 20px;
}
.template-blog .pagination__list {
  justify-content: center;
}
.blogs .post__hidden-info {
  display: block !important;
  position: relative !important;
}
.blogs .position-absolute {
  display: block !important;
  position: relative !important;
}
.blogs .container {
  max-width: 100%;
  width: 100%;
}
.blogs .container > .row {
  padding-bottom: 0px !important;
}
.blogs .container .row {
  margin-left: 0px;
  margin-right: 0px;
}
.blogs .blogs__body {
  padding-left: 0px;
  padding-right: 0px;
}
.blogs h3 {
  font-size: 22px;
  text-align: left;
}
.blogs h3 {
  display: none;
}
.blogs .post__hidden-info h3 {
  display: block !important;
}
.blogs p {
  font-size: 14px;
  text-align: left;
}
.blogs .post {
  margin-bottom: 0px !important;
  min-height: 175px;
}
.blogs .post .position-absolute {
  padding-bottom: 0px !important;
}
/* Cart Page */
#your-shopping-cart .one-product {
  text-align: center;
}
#your-shopping-cart .one-product .row {
  flex-direction: column;
}
#your-shopping-cart .one-product__image {
  max-width: 130px;
}
#your-shopping-cart .one-product__image .rimage {
  height: 130px;
}
#your-shopping-cart .one-product__image .rimage img {
  object-fit: cover;
}
#your-shopping-cart .one-product .col-12 {
  justify-content: center !important;
  align-items: center !important;
  display: flex;
}
#your-shopping-cart .one-product-info__title a,
#your-shopping-cart .one-product-info__price span {
  font-size: 16px;
}
#your-shopping-cart .one-product-info__title,
#your-shopping-cart .one-product-info__price {
  text-align: center !important;
}
#your-shopping-cart .one-product-info__title {
  margin-bottom: 0px !important;
}
#your-shopping-cart .one-product:before {
  content: "You may be interested in a Velvet Pouch";
  font-size: 18px;
  margin-bottom: 20px !important;
  display: block;
}

#your-shopping-cart .js-page-cart-ajax .container {
  max-width: 100%;
  padding-right: 60px;
  padding-left: 60px;
}
#your-shopping-cart .js-input-quantity-cart,
.key-down,
.key-up {
  height: 40px !important;
  width: 40px !important;
  float: left;
}
#your-shopping-cart .key-up,
.key-down {
  background-color: #0410ad;
}
#your-shopping-cart .key-up svg,
.key-down svg {
  fill: #fff !important;
}
.qty-wrapper {
  width: 120px;
  flex: 0 0 120px;
  padding-left: 0px;
  padding-right: 0px;
}
/* Footer */
#footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  background: #0410ad;
  color: white;
  padding-left: 40px;
  padding-right: 40px;
}
#footer a {
  color: white;
  letter-spacing: 0.5px;
  font-size: 12px;
}
#footer .col-lg-4 {
  display: none;
}
#footer .row {
  width: 100% !important;
}
#footer .d-lg-inline {
  display: none !important;
}
#footer .icon {
  fill: #ffffff;
}
#footer .footer__tape {
  width: 50%;
}
#footer .col-lg-6 {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100%;
  order: 2;
}
#footer .footer__content {
  width: 50%;
}
#footer .col-lg-2 {
  flex: 0 0 100%;
  max-width: 100%;
}
#footer .social-media {
  float: right;
  margin-right: -15px;
}
.footer__menus .list-unstyled .col-lg {
  display: inline-block;
  text-align: right;
  width: calc(20% - 5px);
}
.footer__content {
  display: flex;
  flex-direction: column;
}
.footer__social-media .social-media {
  display: block !important;
  text-align: right;
}
.footer__social-media .social-media a {
  display: inline-block !important;
}
.footer__content .col-lg,
.footer__content .col-lg-6 {
  padding-right: 0px;
}
/* Floating Review Button */
#floating-review-button {
  position: fixed;
  padding: 15px 20px;
  background-color: #040e98 !important;
  text-transform: uppercase;
  color: #fff;
  transform: rotate(-90deg);
  left: -45px;
  top: 48%;
  z-index: 99;
}
/* END Floating Review Button */

/* Homepage Review Summary */

.home-builder-block-id-template--16718637170741__1586280453180-1
  .oke-w-header-content-block {
  text-align: center !important;
  margin-right: 0px !important;
  width: 100% !important;
}

.home-builder-block-id-template--16718637170741__1586280453180-1
  .oke-w-header-content-block
  .oke-w-header-content-module {
  justify-content: center !important;
  text-align: center !important;
  width: 100% !important;
}

#copperzap-natural-germ-killer .home-page-container:nth-child(1) .oke-w-main {
  display: none !important;
}

#copperzap-natural-germ-killer
  .home-page-container:nth-child(1)
  .oke-is-medium
  .oke-w-header-content {
  justify-content: left !important;
}

#copperzap-natural-germ-killer
  .home-page-container:nth-child(1)
  .oke-w-header-content-block {
  margin-bottom: 0px !important;
}

#copperzap-natural-germ-killer .home-page-container:nth-child(1) .okeReviews {
  padding-top: 10px;
  padding-bottom: 10px;
}

#copperzap-natural-germ-killer
  .home-page-container:nth-child(1)
  .promobox__text-3 {
  margin-top: 0px !important;
}
/* END Homepage Review Summary */

/* Review Page */
#reviews ul:not(.list-unstyled):not([list-unstyled]) li::before {
  display: none !important;
}

/* Cookie Policy Popup */
.notification-cookies__button-info {
  color: white !important;
}
.notification-cookies__button-info:hover {
  text-decoration: underline !important;
}

/* 10% Off Popup */
.needsclick ul {
  list-style-type: none !important;
}
.needsclick li:before {
  display: none !important;
}

/* Afterpay Popup */

#afterpay-modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  display: none;
}
#afterpay-modal {
  background: #b2fce4;
  max-width: 775px;
  width: 100%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  padding: 20px;
  display: none;
}
.step-container {
  display: flex;
  flex-direction: row;
}
.process-step {
  flex: 0 0 25%;
  text-align: center;
}
.afterpay-modal-logo {
  width: 100%;
  max-width: 275px;
  height: auto;
  clear: both;
  margin: 0 auto;
  margin-top: 40px;
}
.afterpay-close-button {
  float: right;
  cursor: pointer;
}
#afterpay-modal h3 {
  margin: 0;
  font-size: 44px;
  font-weight: 600;
  line-height: 1.1;
  text-align: center;
  margin-top: 10px;
}
#afterpay-modal h3 div {
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Italian Plate No2 Expanded", sans-serif;
  color: #000;
}
#afterpay-modal .process-icon-container svg {
  width: auto;
  height: auto;
  max-width: 110px;
  max-height: 95px;
  margin-bottom: 20px;
}
#afterpay-modal .afterpay-modal-wrapper div {
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Italian Plate No2 Expanded", sans-serif;
  color: #000;
}
#afterpay-modal .process-icon-container svg .c1 {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}
#afterpay-modal .afterpay-modal-wrapper * {
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Italian Plate No2 Expanded", sans-serif;
  color: #000;
}
#afterpay-modal .afterpay-mobile,
#afterpay-modal .sign-up-mobile {
  display: none;
}
#afterpay-modal .process-icon-container {
  display: flex;
  justify-content: center;
  height: 118px;
}
#afterpay-modal .step-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
#afterpay-modal .process-subheading {
  padding-left: 20px;
  padding-right: 20px;
}
#afterpay-modal .disclaimer {
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Italian Plate No2 Expanded", sans-serif;
  color: #000;
}
#afterpay-modal .disclaimer a {
  text-decoration: underline;
}
#afterpay-modal .disclaimer * {
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Italian Plate No2 Expanded", sans-serif;
  color: #000;
}

/* Mobile Reponsiveness */
@media (max-width: 1200px) {
  #after-pay-original-logo {
    float: none;
    margin-top: 10px;
    margin-left: 0px;
  }
  .blogs .col-md-6 {
    margin-bottom: 20px;
  }
  .js-page-cart-ajax .cart-first,
  .js-page-cart-ajax .cart-second {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .js-page-cart-ajax .cart-second {
    margin-top: 25px !important;
  }
}
@media (max-width: 1024px) {
  .product-gallery__thumbnail_slider {
    display: none !important;
  }
  .product-collection__button-quick-view-mobile {
    display: none !important;
  }
  .template-blog h2 {
    margin-bottom: 0px;
  }
  .template-blog h1 {
    margin-top: 20px;
  }
  .footer__section-content {
    display: block !important;
  }
  .footer__section-head {
    display: none !important;
  }
  .footer__border-top {
    border-top: 0px !important;
  }
  .footer__content {
    order: 1;
  }
  #footer .footer__content,
  #footer .footer__tape {
    width: 100% !important;
    flex-direction: row;
  }
  #footer {
    display: block !important;
  }
  .footer .container {
    max-width: 100%;
  }
  #footer .py-40 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  #footer .social-media {
    float: none !important;
    clear: both !important;
    margin-bottom: 30px;
  }
}
@media (max-width: 900px) {
  .template-product .product-page-info__payments {
    position: relative;
  }
  .template-product .product-page-info__payments label {
    padding-right: 75px;
  }
}
@media (max-width: 768px) {
  .footer__menus .list-unstyled .col-lg {
    width: 100% !important;
  }
  #footer .list-unstyled li {
    text-align: center !important;
  }
  .footer__border.d-lg-none {
    border-top: none !important;
  }
  #your-shopping-cart .js-input-quantity-cart,
  .key-down,
  .key-up {
    height: 40px !important;
    width: 30px !important;
    color: black;
    padding: 0px;
    text-align: center !important;
  }
  .template-product .product-page-info__payments label {
    padding-right: 0px;
  }
  #after-pay-original-logo {
    float: right;
    margin-top: -7px;
    margin-left: 5px;
  }
}
@media (max-width: 600px) {
  #header {
    position: relative !important;
    z-index: 2;
  }
  #afterpay-modal .afterpay-desktop,
  #afterpay-modal .sign-up-desktop {
    display: none;
  }
  #afterpay-modal .afterpay-mobile,
  #afterpay-modal .sign-up-mobile {
    display: block;
  }
  #afterpay-modal {
    top: 0px;
    left: 0px;
    transform: none;
  }
  #afterpay-modal .process-step {
    display: table;
    width: 100%;
  }
  #afterpay-modal .step-container {
    flex-direction: column;
  }
  #afterpay-modal .process-icon-container svg {
    width: 100%;
    max-height: 65px;
  }
  #afterpay-modal h3 {
    font-size: 22px;
  }
  .afterpay-modal-logo {
    max-width: 125px;
  }
  #afterpay-modal .process-icon-container {
    display: table-cell;
    width: 90px;
    height: 65px;
    margin-bottom: 20px;
    vertical-align: middle;
    position: relative;
    top: 10px;
  }
  #afterpay-modal .process-subheading {
    text-align: left;
    display: table-cell;
    vertical-align: middle;
  }
  #afterpay-modal .step-container {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  #afterpay-model .process-step {
    overflow: hidden;
    padding: 10px;
  }
}
@media (max-width: 565px) {
  #your-shopping-cart .js-input-quantity-cart,
  .key-down,
  .key-up {
    height: 35px !important;
    width: 25px !important;
  }
}
@media (max-width: 540px) {
  #your-shopping-cart .js-input-quantity-cart,
  .key-down,
  .key-up {
    height: 40px !important;
    width: 40px !important;
  }
}
@media (max-width: 500px) {
  #after-pay-original-logo {
    float: none;
    margin-top: 10px;
    margin-left: 0px;
  }
}
@media (max-width: 600px) {
  .home-page-container,
  .product-page .container {
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% - 20px);
  }
  .product-page-info__quantity {
    width: 110px;
    display: block;
    margin: 0 auto;
  }
  .home-page-container:nth-child(1) {
    margin-top: 10px;
  }
  .home-page-container .button {
    max-width: 150px;
    display: block !important;
    margin: 0 auto;
  }
  .pt-40,
  .pt-30 {
    padding-top: 20px !important;
  }
  .pb-40,
  .pb-30 {
    padding-bottom: 20px !important;
  }
  .h4 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .blogs .h4 {
    padding-left: 0px;
    padding-right: 0px;
  }
  h2,
  h4,
  .mb-30,
  .my-30 {
    margin-bottom: 20px !important;
  }
  .product-page-info__share {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .social-share__col {
    display: block;
    flex: 0 0 100% !important;
    max-width: 100px !important;
    width: 100px !important;
  }
  .template-product .carousel__item {
    float: none !important;
    display: block !important;
    margin: 0 auto !important;
    width: 265px !important;
  }
  .template-blog h2 {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0px !important;
  }
}
@media (max-width: 400px) {
  #after-pay-original-logo {
    overflow: visible !important;
  }
}
